.Linktree {
  .digitalwunder {
    background-color: #000;
    border-color: #000;
    color: #fff;
  }
  .soundcloud {
    background-color: #ff8800;
    border-color: #ff8800;
    color: #fff;
  }
  .dwio {
    background-color: #27586b;
    border-color: #27586b;
    color: #fff;
  }
}
